import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Header from '../Components/Header';

const Refer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [user, setUser] = useState();
  const [cardData, setGame] = useState([]);
  const [WebSitesettings, setWebsiteSettings] = useState("");

  const Cashheader = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    axios.get(`${baseUrl}me`, { headers })
      .then((res) => {
        setUser(res.data);
        Allgames(res.data.referral_code);
      })
      .catch((e) => {
        alert(e.msg);
      });
  };

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    await axios.get(`${baseUrl}referral/code/${id}`, { headers })
      .then((res) => {
        setGame(res.data);
      });
  };

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setWebsiteSettings(data);
  };

  useEffect(() => {
    Cashheader();
    fetchData();
  }, []);

  const copyCode = () => {
    const referralLink = `https://haryanaludo.com/login/${user.referral_code}`;
    navigator.clipboard.writeText(referralLink).then(() => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Referral Link Copied',
        showConfirmButton: false,
        timer: 1200,
      });
    }).catch((err) => {
      console.error('Failed to copy referral link: ', err);
    });
  };

  if (user === undefined) {
    return null;
  }

  return (
    <>
<Header user={user} />
<div className="container-fluid p-3">
  <div className="row justify-content-center">
    <div className="col-lg-6 col-md-8 col-sm-10">
      
      {/* Referral Information Card */}
      <div className="card shadow-sm p-4 mt-5" style={{ border: '2px solid #000', borderRadius: '10px', backgroundColor: '#fff' }}>
        <h5 className="text-center font-weight-bold mb-4" style={{ color: '#000' }}>Earn Unlimited with Referrals</h5>
        <div className="d-flex justify-content-center mb-3">
        
        </div>
        <h6 className="text-center font-weight-bold mb-1" style={{ color: '#000' }}>Earn Unlimited</h6>
        <p className="text-center" style={{ color: '#000' }}>Refer your friends and start earning!</p>

        {/* Earnings and Progress */}
        <div className="d-flex justify-content-between font-12" style={{ color: '#000' }}>
          <span>Current Earning: <b>₹{user.referral_wallet}</b></span>
          <Link className="btn-link" to="/Redeem">Redeem</Link>
        </div>
        <div className="d-flex justify-content-between font-12 mt-1" style={{ color: '#000' }}>
          <span>Total Earned: <b>₹{user.referral_earning}</b></span>
        </div>
        <div className="progress my-2" style={{ height: '8px' }}>
          <div
            className="progress-bar bg-dark"
            role="progressbar"
            aria-valuenow={user.referral_earning}
            aria-valuemax={10000}
            style={{
              width: `${(user.referral_earning * 100) / 10000}%`,
            }}
          ></div>
        </div>
        <div className="d-flex justify-content-between font-12" style={{ color: '#000' }}>
          <span>Max: ₹10,000</span>
          <Link className="btn-link" to="/update-pan">Upgrade Limit</Link>
        </div>

        {/* Referral Code */}
        <div className="text-center mt-3 font-weight-bold" style={{ color: '#000' }}>
          <span>Your Refer Code: {user.referral_code}</span>
          <i
            className="ri-clipboard-fill ml-2"
            style={{ fontSize: '20px', color: '#000', cursor: 'pointer' }}
            onClick={copyCode}
          ></i>
          <div className="text-center mt-2">
            <button className="btn btn-sm btn-dark" style={{ borderRadius: '4px', fontSize: '8px', padding: '1px 4px' }} onClick={copyCode}>
              <span className="font-9" style={{ color: 'white', fontWeight: 'bold' }}>Copy Refer Link</span>
            </button>
          </div>
        </div>
        <div className="text-center font-12 mt-2" style={{ color: '#000' }}>
          Total Refers: <b>{cardData && cardData}</b>
        </div>
      </div>

      {/* Refer & Earn Rules Card */}
      <div className="card shadow-sm p-4 mt-2" style={{ border: '2px solid #000', borderRadius: '10px', backgroundColor: '#fff' }}>
        <h6 className="font-11 font-weight-bold text-center mb-3" style={{ color: '#000' }}>Refer & Earn Rules</h6>
        <div className="d-flex align-items-center mb-2">
          <picture>
            <img
              alt="Rule 1"
              width="70px"
              src={process.env.PUBLIC_URL + 'Images/refer/giftbanner.png'}
              className="snip-img"
            />
          </picture>
          <div className="ml-3">
            <p className="font-10 mb-1" style={{ color: '#000' }}>
              Earn <strong>3% Commission</strong> on your referral's winnings when they sign up.
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <picture>
            <img
              alt="Rule 2"
              width="70px"
              src={process.env.PUBLIC_URL + 'Images/refer/banner.png'}
              className="snip-img"
            />
          </picture>
          <div className="ml-3">
            <p className="font-10 mb-1" style={{ color: '#000' }}>
              Get <strong>₹200</strong> when your referral plays a battle for ₹10,000.
            </p>
          </div>
        </div>
      </div>

      {/* Share Section */}
      <div className="card shadow-sm p-4 mt-2" style={{ border: '2px solid #000', borderRadius: '10px', backgroundColor: '#fff' }}>
        <h6 className="text-center font-weight-bold mb-3" style={{ color: '#000' }}>Share Your Referral Link</h6>
        <div className="d-flex justify-content-around">
          <a
            href={`whatsapp://send?text=Play Ludo and earn ₹10000 daily. https://haryanaludo.com/login/${user.referral_code} Register Now, My refer code is ${user.referral_code}.`}
          >
            <button className="btn btn-dark btn-sm">
              <span className="font-9" style={{ color: 'white' }}><b>WhatsApp</b></span>
            </button>
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=https://haryanaludo.com/login/${user.referral_code}`}
          >
            <button className="btn btn-dark btn-sm">
              <span className="font-9" style={{ color: 'white' }}><b>Facebook</b></span>
            </button>
          </a>
          <a
            href={`https://twitter.com/intent/tweet?text=Play Ludo and earn ₹10000 daily. https://haryanaludo.com/login/${user.referral_code} Register Now, My refer code is ${user.referral_code}.`}
          >
            <button className="btn btn-dark btn-sm">
              <span className="font-9" style={{ color: 'white' }}><b>Twitter</b></span>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
    </>
  );
};

export default Refer; 
